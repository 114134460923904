import { ConnectApi } from '../plugins/connectApi';
import StackTracey from 'stacktracey';
import { isError } from './toolbox';
export class ErrorTracker {
	constructor () {
		this.trackStack = [];
		this.errorCount = 0;
		this.errorCountMax = 4;
		this.isReportingEnabled = true;
		this.intervalHandle = setInterval(this.check.bind(this), 1000);
	}

	removeVersionNumber (stack) {
		if (!stack) {
			return stack;
		}
		const { version } = js_params;
		const stackWithoutVersionNumber = stack.replace(new RegExp(`/v${version}/`, 'g'), '/v999/');
		return stackWithoutVersionNumber;
	}

	async reportError (error) {
		if (error?.name === 'AbortError') {
			return;
		}
		if (!isError(error)) {
			return;
		}
		const { version } = js_params;
		// eslint-disable-next-line prefer-const
		let { message, fileName, lineNumber, columnNumber, stack } = error;
		stack = this.removeVersionNumber(stack);
		if (this.isReportingEnabled) {
			try {
				const stackFrameArray = await new StackTracey(stack).cleanAsync();
				const stackFrameArrayAsTable = stackFrameArray.asTable({ callee: 30000, file: 30000, sourceLine: 30000 });
				const origin = stackFrameArray.items[0];
				fileName = origin.fileRelative || fileName;
				columnNumber = origin.column || columnNumber;

				let combinedMessage = message + '\n' + stackFrameArrayAsTable + '\n~~~~~~\n\n' + stack;
				if (!stack) {
					const fallbackErrorMessage = error?.toString?.() || 'No stack trace and no toString method';
					combinedMessage = message + '\n' + stackFrameArrayAsTable + '\n~~~~~~\n\n' + fallbackErrorMessage;
				}

				const errorBody = {
					message: combinedMessage,
					source: fileName,
					site_version: version,
					colno: columnNumber,
					http_referrer: document.referrer
				};

				errorBody.source = this.removeVersionNumber(errorBody.source);

				if (error?.connectApiParameters) {
					errorBody.parameters = error.connectApiParameters;
				}
				this.trackStack.push(errorBody);
			} catch (fallback) {
				const errorBody = {
					message: message + (stack ? '\n' + stack : ''),
					source: fileName,
					site_version: version,
					colno: columnNumber,
					http_referrer: document.referrer
				};
				if (error?.connectApiParameters) {
					errorBody.parameters = error.connectApiParameters;
				}

				error.source = this.removeVersionNumber(error.source);
				errorBody.message = this.removeVersionNumber(errorBody.message);

				this.trackStack.push(errorBody);
			}
		}
		return false;
	}

	check () {
		// If there are any new errors on the stack
		if (this.trackStack.length) {
			// Increase the number of errors reported
			this.errorCount += this.trackStack.length;
			// Update the isReportingEnabled boolean
			this.isReportingEnabled = this.errorCount < this.errorCountMax;
			// Submit the first three errors to ErrorReporting
			ConnectApi('ErrorReporting', 'report', [this.trackStack.slice(0, this.errorCountMax), document.location.href]).then(() => {
				// Clear the stack on sucessful report
				this.trackStack = [];
			});
		}
		// If we have reported the maximum number of errors then clear the interval
		if (!this.isReportingEnabled) {
			clearInterval(this.intervalHandle);
		}
	}
}
